#contact-main-div {
    background-color: "#fff";
    margin: 3em;
}

.contacts-btn-div {
    text-align: center;
}

.contact-links {
    padding: 0px 3px;
}

.image-header {
    position: absolute;
    top: 5em;
    text-align: center;
    width: 100%;
    z-index: 2;

}

.image-contact-div {
    background-color: rgb(0, 0, 0);
}

.image-contacts {
    width: 100em;
    height: 20em;
    object-fit: cover;
    opacity: 0.5;
}

.teamsHeader {
    text-align: center;
    margin: 2em 0em;
}

.team-image-div {
    margin-left: 8%;
}

.team-image {
    border-radius: 8px;
}

.team-service {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    margin-top: -25px;
}

.teams-contact-btn {
    color: #fff; 
    background-color: #5658DD;
    padding: 10px 20px;
    border: none;
    font-size: 16px;
    margin: 2em 0em;
    border-radius: 4px;
    cursor: pointer;
}

.teams-contact-btn:hover {
    background-color: #6163FF;
}

.team-image-footer {
    width: 300px;
    border-radius: 8px;
}

/* TRADEMARK */

.trademark-div {
    display: flex;
    align-items: center;
}

.trademark-title {
    margin-left: 0.5em;
}

.trademark-desc {
    font-size: 16px;
    padding: 0em 1em;
}

.trademark-stamp {
    text-align: center;
    background-color: #1e1e1f;
    border-left: 0.1em solid #6163FF;
    margin: 1em 0em;
}

.trademark-color {
    color: #5658DD;
}

.trademark-header {
    border: 0.1em solid #6163FF;
    padding: 1em;
}

.trademark-btn {
    background-color: #6163FF;
    color: #fff;
    padding: 0.5em 1em;
    border-radius: 6px;
    border: none;
    margin-bottom: 1.5em;
    font-weight: bold;
    font-size: 18px;
}

/* BOOK */

.bookMainContainerDiv {
    margin: 0px 2em;
    margin-top: 5%;
    border: 2px solid #5658DD;
    border-radius: 8px;
    padding: 1em;
}

.bookMainPara {
    text-align: center;
    margin-bottom: 3em;
}

.bookTextInputContainerDiv {
    margin-bottom: 5em;
}

.bookInputContainer {
    /* height: 15em;
    overflow-y: scroll; */
}

.bookErrorMessage {
    color: rgb(228, 3, 3);
    margin: 0.2em 0em;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.content-text {
    padding: 0em 6em;
}

.content-text-spacing {
    padding: 2em 6em;
}

.priceListtableDiv {
    margin: 1em 5em;
}

#amazonAccountManagement {
    margin: 20px 5em;
}

#consuntancySalesPara {
    padding: 0px 15em;
}

#winningBoxPara {
    text-align: left;
    padding: 0px 15em;
}


@media screen and (max-width: 600px) {
    .content-text {
        padding: 0em 2em;
    }   

    .priceListtableDiv {
        margin: 1em 1em
    }

    #amazonAccountManagement {
        margin: 20px 0.5em;
    }

    #consuntancySalesPara {
        padding: 0px 1em;
    }

    #winningBoxPara {
        padding: 0px 1em;
    }

}

@media screen and (max-width: 900px) {
    #amazonAccountManagement {
        margin: 20px 10px;
    }

    #consuntancySalesPara {
        padding: 0px 2em;
    }

    #winningBoxPara {
        padding: 0px 3em;
    }

}